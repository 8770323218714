import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disconnect,  addToken, doLogin, doLogout } from './web3Slice';
import { Link } from 'react-router-dom';




export default function Home() {

	// ------- selectors -----------
	const userData = useSelector(state => state.web3.userData);


	const dispatch = useDispatch();

	const disconnectIt = () => {
		if (window.confirm("Disconnect wallet?")) {
			dispatch(disconnect());
		}
	}

	return (
		<div className="page">
			
			<h1>SDBN WALLET</h1>
			<div className="page-main">
				<br/>
				<br/>
				<h2 className='mt-2' >Wallet connected</h2>
				<br/>
				<br/>
				<div>Your Accounts are connected...</div>
				<br/>
				<br/>
				<div>
					<a href='https://sunmoney.net'>Go to weboffice</a>
				</div>

				{/*
				<div>status: {userData?.smnetStatus}</div>
				<div>SAS ID: {userData?.smnetData?.user_id}</div>
				<div>Sponsor ID: {JSON.stringify(userData?.smnetData?.sponsor)}</div>
				<br/>
				<br/>*/}

				<hr />


				<div className="other-buttons">
					{!!userData?.userId && <button className='btn btn-outline-secondary' onClick={() => dispatch(doLogout())}  >Logout</button>}
					<button className='btn btn-outline-secondary' onClick={() => disconnectIt()}  > Disconnect wallet</button>
				</div>
			</div>
		</div>
	)
};


