
//export const API_BASE = "http://localhost:25003/api";
//export const API_BASE = "http://176.58.118.145:25001/api";
//export const API_BASE = "https://sunbuy.coimbraits.hu:25001/api";
export const API_BASE = "https://wallet.sdbn-token.com:25003/api";



// ------------------------------- TESTNET ----------------------------------

/*
export const CHAIN = {
	chainId: '0x61',
	chainName: 'Binance Smart Chain Testnet',
	nativeCurrency: {
		name: 'tBNB',
		symbol: 'tBNB',
		decimals: 18
	},
	rpcUrls: ['https://data-seed-prebsc-1-s3.binance.org:8545/'],
	blockExplorerUrls: ['https://testnet.bscscan.com/']
};

*/


// ------------------------------- PRODUCTION ----------------------------------

export const CHAIN = {
	chainId: '0x38',
	chainName: 'Binance Smart Chain',
	nativeCurrency: {
		name: 'Binance Coin',
		symbol: 'BNB',
		decimals: 18
	},
	rpcUrls: ['https://bsc-dataseed.binance.org/'],
	blockExplorerUrls: ['https://bscscan.com/']
}
