import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect, disconnect, doLogin, doLogout, errorClose } from './web3Slice';
import { Modal, Button, NavLink } from 'react-bootstrap';
import { dispAddress } from '../common';
import ToastMessages from '../toastMessages';

import { Route, Link, BrowserRouter as Router, Routes, useNavigate } from "react-router-dom";
import Home from './Home';




export default function App() {

	// ------- selectors -----------
	const status = useSelector((state) => state.web3.status);
	const statusText = useSelector((state) => state.web3.statusText);
	const userData = useSelector(state => state.web3.userData);
	const walletAddress = useSelector((state) => state.web3.walletAddress);
	const dispatch = useDispatch();
	const showSpinner = (['query', 'networksetup'].indexOf(status) > -1);

	const disconnectIt = () => {
		if (window.confirm("Disconnect wallet?")) {
			dispatch(disconnect());
		}
	}
	const [design, setDesign] = useState(true);


	useEffect(() => {
		if (userData.smnetStatus === 'redirect') {
			let url = userData.smnetData;
			let lang = window.localStorage.getItem('lang');
			if (lang) {
				if (url.indexOf('?') == -1)
					url += '?lang=' + lang;
				else
					url += '&lang=' + lang;
			}
			window.location.href = url;
		}

	}, [
		userData.smnetStatus, userData.smnetData
	]);


	return (
		<div className="page-container container" >
			<Modal
				show={status == 'error'}
				centered={true}
				onHide={() => dispatch(errorClose())}
			>
				<Modal.Header closeButton>
					<Modal.Title>Error</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: statusText }}></div>
				</Modal.Body>
				<Modal.Footer>
					{status == 'connecterror' && <Button variant="btn btn-primary" onClick={() => dispatch(connect())} >Try again</Button>}
					{status != 'connecterror' && <Button variant="btn btn-primary" onClick={() => dispatch(errorClose())} >Ok</Button>}
				</Modal.Footer>
			</Modal>

			<Modal
				show={showSpinner}
				backdrop="static"
				keyboard={false}
				centered={true}
			>
				<Modal.Body className='spinner-body' >
					<br /><br />
					<img src="/images/spinner_orange.png" />
					<br /><br />
					<div dangerouslySetInnerHTML={{ __html: status === 'connecting' ? 'connecting...' : statusText }}></div>
					<br /><br />
					<a className='btn btn-outline-secondary btn-sm' onClick={() => window.location.reload()}>Reload page</a>
				</Modal.Body>
			</Modal>

			<div className="page-container" >

				{status == 'disconnected' && <div className="page" style={{ justifyContent: 'center' }}>
					<h1>SDBN WALLET</h1>
					<div className='page-main'>
						<br /><br /><br />
						{!window.ethereum
							&& <a className='btn btn-primary' href="https://metamask.io/" target="_blank">Install metamask</a>
						}
						<br /><br /><br />
						<button className='btn btn-primary' onClick={() => dispatch(connect())}  > Connect wallet</button>
						<br /><br /><br />
					</div>
				</div>}

				{status != 'disconnected' && !userData?.userId && <div className="page" style={{ justifyContent: 'center' }}>
					<h1>SDBN WALLET</h1>
					<div className='page-main'>
						<br /><br /><br />
						<div className="block block-value">
							<span className="title">Wallet address</span>
							<span className="value">{dispAddress(walletAddress)}<span className="dim"></span></span>
						</div>
						<br /><br /><br /><br />
						{<button className='btn btn-primary' onClick={() => dispatch(doLogin())}  >Sign your wallet</button>}
						<br /><br /><br /><br />
						{<button className='btn btn-secondary' onClick={() => disconnectIt()}  > Disconnect wallet</button>}
						<br />
					</div>
				</div>}

				{status != 'disconnected' && userData?.userId && userData.smnetStatus !== 'ok' && <div className="page" style={{ justifyContent: 'center' }}>
					<h1>SDBN WALLET</h1>
					<div className='page-main'>
						<br /><br /><br />
						<h3>No sunmoney.net login</h3>
						<div>{userData.smnetStatus}</div>
						<div>{JSON.stringify(userData.smnetData)}</div>
						<div className="block block-value">
							<span className="title">Wallet address</span>
							<span className="value">{dispAddress(walletAddress)}<span className="dim"></span></span>
						</div>

						<br /><br /><br /><br />
						{<button className='btn btn-primary' onClick={() => dispatch(doLogout())}  > Logout</button>}
						<br /><br /><br /><br />
						{<button className='btn btn-secondary' onClick={() => disconnectIt()}  > Disconnect wallet</button>}
						<br />

					</div>
				</div>}

				{['query', 'ready', 'error'].indexOf(status) >= 0 && (userData?.userId) && (userData?.smnetStatus === 'ok') &&

					<Router  >
						<Routes>
							<Route path="/" element={<Home />} />
						</Routes>
					</Router>
				}


				<ToastMessages />
				<div className='popup'> {status}&nbsp;<button onClick={() => designIt(!design)}>design</button></div>
			</div>
			<div className='logo-sunmoney'><img src={require("../images/sunmoney_logo.png")} /></div>
			<div className='logo-sdbn'><img src={require("../images/sdbn_logo.png")} /></div>
		</div>
	)
};


