import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import store from './app/store'
import { Provider } from 'react-redux'
import { messageCleanup, parentReferralNameSet, web3init } from './app/web3Slice';


store.dispatch(web3init());

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
console.log('params', params);
if (params && (params.lang=='en' || params.lang=='hu')) {
	window.localStorage.setItem('lang', params.lang);
}
if (params && params.parentReferral) {
	if (!window.localStorage.getItem('parentReferral'))
		window.localStorage.setItem('parentReferral', params.parentReferral);
}
{
const parentReferralName = window.localStorage.getItem('parentReferral')
if (parentReferralName)
	store.dispatch(parentReferralNameSet(parentReferralName));
}

setInterval(() => {
	if (store.getState().web3.messages.length>0)
		store.dispatch(messageCleanup());
}, 500);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
